class Accordion {
  constructor(selector) {
    this.button = selector;
    // this.contentToReveal = this.button.nextElementSibling;
    this.init();
  }

  init() {
    this.button.addEventListener('click', (e) => {
      e.preventDefault();

      this.currentAriaExpanded = this.button.getAttribute('aria-expanded');
      this.newAriaExpanded =
        this.currentAriaExpanded === 'true' ? 'false' : 'true';
      this.button.setAttribute('aria-expanded', this.newAriaExpanded);
      // this.handleAccordion();
    });
  }

  handleAccordion() {
    if (this.contentToReveal.style.maxHeight) {
      this.hideText();
    } else {
      this.showText();
    }
  }

  showText() {
    this.button.classList.add('is-visible');
    this.contentToReveal.classList.add('is-visible');
    this.contentToReveal.style.maxHeight = `${this.contentToReveal.scrollHeight}px`;
  }

  hideText() {
    this.button.classList.remove('is-visible');
    this.contentToReveal.classList.remove('is-visible');
    this.contentToReveal.style.maxHeight = null;
  }
}

export default Accordion;
